import React, { CSSProperties, FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import Sparkle from './Sparkle'

import styles from './FancyButton.module.scss'

interface FancyButtonProps {
  className?: string
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent) => void
  style?: CSSProperties
  type?: 'button' | 'submit' | 'reset'
  form?: string

  href?: string
  download?: string

  /* Pulled from react-router-dom, to forward to 'link' */
  to?:
    | string
    | {
        pathname?: string
        search?: string
        state?: any
        hash?: string
        key?: string
      }
  push?: boolean
  from?: string
  path?: string
  exact?: boolean
  strict?: boolean

  target?: string
}

const FancyButton: FC<PropsWithChildren<FancyButtonProps>> = ({
  className,
  href,
  to,
  children,
  disabled,
  loading,
  ...otherProps
}) => {
  /* If we want an anchor... */
  if (href) {
    delete otherProps.type
    delete otherProps.push
    delete otherProps.from
    delete otherProps.path
    delete otherProps.exact
    delete otherProps.strict
    if (disabled) {
      delete otherProps.onClick
      return (
        <span className={cx(styles.fancyButton, className)} {...otherProps}>
          <div className={styles.container}>
            {loading ? <Sparkle className={styles.loadingIcon} /> : null}
            <span className={styles.content}>{children}</span>
          </div>
        </span>
      )
    } else {
      return (
        <a href={href} className={cx(styles.fancyButton, loading ? styles.loading : null, className)} {...otherProps}>
          <div className={styles.container}>
            {loading ? <Sparkle className={styles.loadingIcon} /> : null}
            <span className={styles.content}>{children}</span>
          </div>
        </a>
      )
    }
  }

  /* If we want an internal link... */
  if (to) {
    delete otherProps.type
    if (disabled) {
      delete otherProps.onClick
      return (
        <span className={cx(styles.fancyButton, className)} {...otherProps}>
          <div className={styles.container}>
            {loading ? <Sparkle className={styles.loadingIcon} /> : null}
            <span className={styles.content}>{children}</span>
          </div>
        </span>
      )
    } else {
      return (
        <Link to={to} className={cx(styles.fancyButton, loading ? styles.loading : null, className)} {...otherProps}>
          <div className={styles.container}>
            {loading ? <Sparkle className={styles.loadingIcon} /> : null}
            <span className={styles.content}>{children}</span>
          </div>
        </Link>
      )
    }
  }

  /* Otherwise, we want a button. */
  // Delete link props just in case.
  delete otherProps.push
  delete otherProps.from
  delete otherProps.path
  delete otherProps.exact
  delete otherProps.strict
  delete otherProps.download
  return (
    <button
      className={cx(styles.fancyButton, loading ? styles.loading : null, className)}
      disabled={disabled}
      {...otherProps}
    >
      <div className={styles.container}>
        {loading ? <Sparkle className={styles.loadingIcon} /> : null}
        <span className={styles.content}>{children}</span>
      </div>
    </button>
  )
}

export default FancyButton
